<!--
  PACKAGE_NAME : src\pages\esp\system\site\list.vue
  FILE_NAME : site
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 사이트 설정 목록
-->
<template>
  <div class="content-themes">
    <div class="page-sub-box">
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="handleInitNewRow" />
    </div>
  </div>
</template>

<script>
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid },
    props: {
      codeKey: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        dataGrid: {
          keyExpr: 'id',
          refName: 'siteGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          repaintChangesOnly: false,
          hoverStateEnabled: true,
          dataSource: [],
          apiActionNm: {
            merge: 'SITE_LIST_INSERT',
          },
          showActionButtons: {
            select: true,
            copy: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => this.handleDeleteSite(),
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          customEvent: {
            initNewRow: true, // 신규 행 추가시 기본값 설정 여부
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10, //page.size,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            changes: [], //null값으로 두면 에러남
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch'] - batch에서는 inserted 필요없음
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            allowSelectAll: true, // 전체 선택 허용 여부
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, //scrolling infinite 시 복수 선택 에러 방지
          },
          columns: [
            {
              caption: 'ID',
              dataField: 'id',
              width: 80,
              alignment: 'center', // left center right
              visible: false,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '사이트',
              dataField: 'site',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              requiredRule: {
                message: '필수값 입니다.',
              },
            },
            {
              caption: '사이트명',
              dataField: 'siteNm',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              requiredRule: {
                message: '필수값 입니다.',
              },
            },
            {
              caption: 'CTI 구분',
              dataField: 'ctiType',
              width: 110,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: 'CTI 버전',
              dataField: 'ctiVer',
              width: 110,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: 'CTI 연결 정보',
              dataField: 'ctiConnstr',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: 'ERS 스키마',
              dataField: 'ersSchema',
              width: 150,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: '솔루션형식',
              dataField: 'solutionType',
              width: 120,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: 'psdualFl',
              dataField: 'psdualFl',
              width: 110,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: 'SORT',
              dataField: 'siteOrd',
              width: 110,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: 'EMC 사용여부',
              dataField: 'emcFl',
              alignment: 'center',
              width: 110,
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.common.stringViewFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '사용여부',
              dataField: 'viewFl',
              width: 110,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowHeaderFiltering: false,
              sortOrder: 'none',
              lookup: {
                dataSource: this.$_enums.common.stringViewFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    methods: {
      /** @description: 데이터 조회 메서드 */
      async selectDataList(sort = '+siteOrd') {
        let vm = this;

        vm.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params };

            const payload = {
              actionname: 'SITE_LIST_ALL',
              data: { params: params },
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              let rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };

              vm.$refs.siteGrid.totalCount = rtnData.totalCount;

              return rtnData;
            } else {
              return () => {
                throw 'Data Loading Error';
              };
            }
          },
        });
      },
      /** @description: 신규 행 추가시 기본값 설정 */
      handleInitNewRow(e) {
        e.data.viewFl = 'Y';
        e.data.emcFl = 'N';
        e.data.psdualFl = 'N';
      },
      /**
       * @description: 사이트 삭제
       * @return {Promise<void>}
       */
      async handleDeleteSite() {
        const selectedRows = this.$refs.siteGrid.getGridInstance.getSelectedRowsData();
        if (selectedRows.length === 0) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '선택된 데이터가 없습니다.' }));
          return;
        }

        if (await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
          const payload = {
            actionname: 'SITE_LIST_DELETE',
            data: { data: selectedRows.map(row => row.id) },
            loading: true,
            useErrorPopup: true,
          };
          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
            this.$refs.siteGrid.refreshData();
          }
        }
      },
    },
    mounted() {
      this.selectDataList();
    },
  };
</script>

<style scoped></style>
